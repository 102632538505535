import {
  Action,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from 'vuex-module-decorators';

import {
  AdminDto,
  Client,
  CreateAdminDto,
  CreateSchoolDto,
  GetSchoolClassDto,
  GetSchoolDto,
  UpdateSchoolDto,
} from '@/api/types/api';
import axios from 'axios';
import download from 'downloadjs';
import { API_CLIENTS, API_USERS } from '../../utils/api';

const name = 'client';

@Module({ namespaced: true, name })
export default class ClientModule extends VuexModule {
  clients: GetSchoolDto[] = [];
  client: GetSchoolDto = {
    id: '',
    schoolId: '0',
    keycloakId: '',
    schoolName: '',
    street: '',
    postcode: '',
    city: '',
    country: '',
    licenseUntil: '',
    classes: [],
    clientType: Client.school,
    admins: [],
    url: '',
    slug: '',
    validTests: 0,
    isTutorialOnly: false,
  };

  get currentClient(): GetSchoolDto {
    return this.client;
  }

  get allClients(): GetSchoolDto[] {
    return this.clients;
  }

  @MutationAction
  async fetchClient(schoolId: string): Promise<any> {
    const res = await axios.get(`${API_CLIENTS}/${schoolId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return { client: res.data };
  }

  @MutationAction
  async fetchAllClients(): Promise<any> {
    const res = await axios.get(`${API_CLIENTS}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return { clients: res.data };
  }

  @Action({ rawError: true })
  async addClient(client: CreateSchoolDto): Promise<any> {
    return await axios.post(`${API_CLIENTS}`, client, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  @Action({ rawError: true })
  async updateClient(payload: {
    groupId: string;
    client: UpdateSchoolDto;
  }): Promise<any> {
    return await axios.patch(
      `${API_CLIENTS}/${payload.groupId}`,
      payload.client,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  @Action({ rawError: true })
  async fetchAdmins(payload: {
    schoolId: string;
    adminIds: string[];
  }): Promise<any> {
    const res = await axios.get(`${API_CLIENTS}/${payload.schoolId}/admins`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        ids: payload.adminIds.join(','),
      },
    });
    return res.data;
  }

  @Action({ rawError: true })
  async addOrRemoveAsAdmin(payload: {
    schoolId: string;
    adminDto: AdminDto;
  }): Promise<any> {
    const res = await axios.post(
      `${API_CLIENTS}/${payload.schoolId}/admin`,
      payload.adminDto,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return res.data;
  }

  @Action({ rawError: true })
  async addNewAdmin(payload: {
    schoolId: string;
    adminDto: CreateAdminDto;
  }): Promise<any> {
    const res = await axios.post(
      `${API_CLIENTS}/${payload.schoolId}/admin/new`,
      payload.adminDto,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return res.data;
  }

  @Action({ rawError: true })
  async deleteStudents(schoolId: string): Promise<any> {
    return await axios.delete(`${API_CLIENTS}/${schoolId}/students`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  @Action({ rawError: true })
  async deleteClient(id: string): Promise<any> {
    return await axios.delete(`${API_CLIENTS}/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  @Action({ rawError: true })
  async deleteAdmin(payload: { schoolId: string; id: string }): Promise<any> {
    const res = await axios.delete(
      `${API_USERS}/${payload.schoolId}/${payload.id}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    return res;
  }

  @Action({ rawError: true })
  async deleteAllStudentsOfClass(payload: {
    schoolId: string;
    grade: string;
  }): Promise<any> {
    const res = await axios.delete(
      `${API_CLIENTS}/superadmin/${payload.schoolId}/students/${payload.grade}`,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    return res;
  }

  @Action({ rawError: true })
  async addSchoolClass(payload: {
    schoolId: string;
    name: string;
  }): Promise<any> {
    const res = await axios.post(
      `${API_CLIENTS}/schools/${payload.schoolId}/class`,
      { name: payload.name },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    return res;
  }

  @Action({ rawError: true })
  async renameSchoolClass(payload: {
    schoolId: string;
    classId: string;
    newName: string;
  }): Promise<any> {
    const res = await axios.patch(
      `${API_CLIENTS}/schools/${payload.schoolId}/${payload.classId}/name`,
      { name: payload.newName },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    return res;
  }

  @Action({ rawError: true })
  async downloadAvv(client: GetSchoolDto): Promise<any> {
    return await axios
      .get(`${API_CLIENTS}/avv/${client.schoolId}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf',
          Accept: 'application/pdf',
        },
      })
      .then((res) => {
        const content = res.headers['content-type'];
        download(
          res.data,
          `AVV_${client.schoolName.replaceAll(' ', '_')}`,
          content,
        );
      });
  }

  @Action({ rawError: true })
  async downloadAdminManual(payload: {
    client: GetSchoolDto;
    code: string;
  }): Promise<any> {
    return await axios
      .get(
        `${API_CLIENTS}/manual/${payload.client.schoolId}/admin/${payload.code}`,
        {
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
            Accept: 'application/pdf',
          },
        },
      )
      .then((res) => {
        const content = res.headers['content-type'];
        download(
          res.data,
          `Erste_Schritte_mit_Leo-Lesetest_${payload.client.schoolName.replaceAll(
            ' ',
            '_',
          )}_Admin`,
          content,
        );
      });
  }

  @Action({ rawError: true })
  async downloadTeacherTherapistManual(client: GetSchoolDto): Promise<any> {
    return await axios
      .get(`${API_CLIENTS}/manual/${client.schoolId}/teacher-or-therapists`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf',
          Accept: 'application/pdf',
        },
      })
      .then((res) => {
        const content = res.headers['content-type'];
        const name =
          client.clientType === Client.school
            ? 'LehrerInnen'
            : 'TherapeutInnen';
        download(
          res.data,
          `Erste_Schritte_mit_Leo-Lesetest_Anleitung_für_${name}_${client.schoolName.replaceAll(
            ' ',
            '_',
          )}`,
          content,
        );
      });
  }

  @Action({ rawError: true })
  async downloadCsv(): Promise<any> {
    return await axios
      .get(`${API_CLIENTS}/export/contacts/csv`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'text/csv',
          Accept: 'text/csv',
        },
      })
      .then((res) => {
        download(res.data, 'leo-lesetest_kunden.csv', 'text/csv');
      });
  }

  @Action({ rawError: true })
  async importToMongoDb(): Promise<any> {
    return await axios.post(
      `${API_CLIENTS}/schools/create/all`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  @Action({ rawError: true })
  async getCurrentClasses(schoolId: string): Promise<GetSchoolClassDto[]> {
    if (schoolId === '') return [];
    const res = await axios.get(`${API_CLIENTS}/schools/${schoolId}/classes`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
  }

  @Mutation
  reset() {
    this.client = {
      id: '',
      schoolId: '0',
      keycloakId: '',
      schoolName: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      licenseUntil: '',
      classes: [],
      clientType: Client.school,
      admins: [],
      url: '',
      slug: '',
      validTests: 0,
      isTutorialOnly: false,
    };
    this.clients = [];
  }
}

<template>
  <v-dialog v-model="show" max-width="500px" persistent>
    <v-form @submit.prevent="isEdit ? editSchoolClass() : addSchoolClass()">
      <v-card class="pa-2">
        <v-card-title class="d-flex justify-center">
          <p class="text-center text-h4 font-weight-bold">
            {{
              isEdit
                ? $t('manageClass.edit.title')
                : $t('manageClass.add.title')
            }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-text-field
              v-model="name"
              data-testid="name"
              :error-messages="nameErrors"
              label="Name"
              outlined
              dense
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            ></v-text-field>
          </v-col>
          <v-col v-if="isEdit" class="pa-0">
            <p class="text-body-1 text-center">
              {{ $t('manageClass.edit.info') }}
            </p>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            data-testid="cancel-add-school-class-button"
            color="blue darken-1"
            text
            @click="close"
          >
            <p class="ma-0">{{ $t('cancel') }}</p>
          </v-btn>
          <v-btn
            data-testid="add-schoolclass-button"
            color="error"
            :loading="loading"
            type="submit"
          >
            <p class="ma-0">
              {{
                isEdit
                  ? $t('manageClass.edit.button')
                  : $t('manageClass.add.button')
              }}
            </p>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { GetSchoolClassDto } from '@/api/types';
import ClientModule from '@/store/modules/ClientModule';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import UserModule from '@/store/modules/UserModule';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';

@Component({
  components: {},
  mixins: [validationMixin],
  validations: {
    name: { required },
  },
})
export default class ManageClasses extends Vue {
  clientModule: ClientModule = getModule(ClientModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  @Prop()
  show!: boolean;

  @Prop()
  schoolId!: string;

  @Prop()
  schoolClass?: GetSchoolClassDto;

  @Prop()
  classLimit!: number;

  @Prop()
  classCount!: number;

  name: string | null = null;

  loading = false;

  @Watch('show')
  onShowChange() {
    if (this.show) {
      this.$v.$reset();
    }
  }

  @Watch('schoolClass', { immediate: true })
  onSchoolClassChange() {
    if (this.schoolClass) {
      this.name = this.schoolClass.name;
    } else {
      this.name = null;
    }
  }

  get isEdit(): boolean {
    return this.schoolClass !== null;
  }

  async addSchoolClass() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        this.loading = true;

        if (this.classCount >= this.classLimit) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('manageClass.add.classLimit').toString(),
            isSuccess: false,
          });
          return;
        }

        await this.clientModule.addSchoolClass({
          schoolId: this.schoolId,
          name: this.name!.trim()
            // replace whitespices inside the string with a single whitespace
            .replace(/\s+/g, ' ')
            // replace whitespace with underscore
            .replace(/\s/g, '_')
            // only point and underscore are allowed
            .replace(/[^a-zA-Z0-9_.äöüßÄÖÜ]/g, ''),
        });
        await this.edited();
        this.snackbarModule.showMessage({
          message: this.$i18n.t('manageClass.add.success').toString(),
          isSuccess: true,
        });
        this.close();
      } catch (error: any) {
        if (error?.response?.status === 409) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('manageClass.edit.conflictError').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('manageClass.add.error').toString(),
            isSuccess: false,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }

  async editSchoolClass() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      try {
        this.loading = true;

        // INFO go through school class id
        await this.clientModule.renameSchoolClass({
          schoolId: this.schoolId,
          classId: this.schoolClass!.id,
          newName: this.name!.trim()
            // replace whitespices inside the string with a single whitespace
            .replace(/\s+/g, ' ')
            // replace whitespace with underscore
            .replace(/\s/g, '_')
            // only point and underscore are allowed
            .replace(/[^a-zA-Z0-9_.äöüßÄÖÜ]/g, ''),
        });
        await this.edited();
        this.snackbarModule.showMessage({
          message: this.$i18n.t('manageClass.edit.success').toString(),
          isSuccess: true,
        });
        this.close();
      } catch (error: any) {
        if (error?.response?.status === 409) {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('manageClass.edit.conflictError').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.$i18n.t('manageClass.edit.error').toString(),
            isSuccess: false,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }

  get nameErrors(): string[] {
    const errors: string[] = [];
    const requiredError = this.$t('manageClass.add.nameError').toString();
    if (!this.$v.name.$dirty) return errors;
    !this.$v.name.required && errors.push(requiredError);
    return errors;
  }

  @Emit()
  async edited() {
    // INFO if the user is an admin, we need to update the user object
    const hasAdminRights = this.schoolModule.adminIds.includes(
      this.userModule.currentUser?._id,
    );
    if (hasAdminRights) {
      await this.userModule.whoAmI(this.schoolModule.schoolId!);
    }
    return true;
  }

  @Emit()
  close() {
    this.$v.$reset();
    this.name = null;
    return true;
  }
}
</script>

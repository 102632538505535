/* tslint:disable */
/* eslint-disable */
/**
 * Lesikus API
 * API docs for Lesikus
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdminDto
 */
export interface AdminDto {
    /**
     * 
     * @type {string}
     * @memberof AdminDto
     */
    'keycloakId': string;
}
/**
 * 
 * @export
 * @interface BasicSchoolDto
 */
export interface BasicSchoolDto {
    /**
     * 
     * @type {string}
     * @memberof BasicSchoolDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BasicSchoolDto
     */
    'admins': Array<string>;
    /**
     * 
     * @type {Array<GetSchoolClassDto>}
     * @memberof BasicSchoolDto
     */
    'classes': Array<GetSchoolClassDto>;
    /**
     * 
     * @type {string}
     * @memberof BasicSchoolDto
     */
    'licenseUntil': string;
    /**
     * 
     * @type {Client}
     * @memberof BasicSchoolDto
     */
    'clientType': Client;
    /**
     * 
     * @type {string}
     * @memberof BasicSchoolDto
     */
    'schoolId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BasicSchoolDto
     */
    'slug': string;
    /**
     * 
     * @type {boolean}
     * @memberof BasicSchoolDto
     */
    'isTutorialOnly': boolean;
    /**
     * 
     * @type {string}
     * @memberof BasicSchoolDto
     */
    'country': string | null;
    /**
     * 
     * @type {number}
     * @memberof BasicSchoolDto
     */
    'validTests'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BasicSchoolDto
     */
    'classLimit'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BasicSchoolDto
     */
    'sharePatients'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Client {
    school = 'school',
    therapy = 'therapy',
    parent = 'parent',
    superadmin = 'superadmin'
}

/**
 * 
 * @export
 * @interface CreateAdminDto
 */
export interface CreateAdminDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'schoolId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdminDto
     */
    'clientType': string;
}
/**
 * 
 * @export
 * @interface CreateSchoolClassDto
 */
export interface CreateSchoolClassDto {
    /**
     * Class Name
     * @type {string}
     * @memberof CreateSchoolClassDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSchoolClassDto
     */
    'teachers'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSchoolClassDto
     */
    'students'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateSchoolDto
 */
export interface CreateSchoolDto {
    /**
     * The name of the school
     * @type {string}
     * @memberof CreateSchoolDto
     */
    'schoolName': string;
    /**
     * The unique identifier for the school
     * @type {string}
     * @memberof CreateSchoolDto
     */
    'schoolId': string;
    /**
     * The kc identifier
     * @type {string}
     * @memberof CreateSchoolDto
     */
    'keycloakId'?: string;
    /**
     * The street address of the school
     * @type {string}
     * @memberof CreateSchoolDto
     */
    'street': string;
    /**
     * The postal code of the school
     * @type {string}
     * @memberof CreateSchoolDto
     */
    'postcode': string;
    /**
     * The city where the school is located
     * @type {string}
     * @memberof CreateSchoolDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolDto
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSchoolDto
     */
    'clientType': string;
    /**
     * The license expiration date for the school
     * @type {string}
     * @memberof CreateSchoolDto
     */
    'licenseUntil': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSchoolDto
     */
    'isTutorialOnly': boolean;
    /**
     * The admin users kcId for the school
     * @type {Array<string>}
     * @memberof CreateSchoolDto
     */
    'admins'?: Array<string>;
    /**
     * The classes in the school
     * @type {Array<CreateSchoolClassDto>}
     * @memberof CreateSchoolDto
     */
    'classes'?: Array<CreateSchoolClassDto>;
    /**
     * 
     * @type {number}
     * @memberof CreateSchoolDto
     */
    'validTests'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSchoolDto
     */
    'sharePatients'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateSchoolDto
     */
    'classLimit'?: number;
}
/**
 * 
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'password': string;
    /**
     * 
     * @type {Role}
     * @memberof CreateUserDto
     */
    'role': Role;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'schoolId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserDto
     */
    'studentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'studentPassword'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserDto
     */
    'doneA'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserDto
     */
    'doneB'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserDto
     */
    'enableA'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserDto
     */
    'enableB'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserDto
     */
    'firstLogin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'downloadedA'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'downloadedB'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserDto
     */
    'age'?: number;
    /**
     * 
     * @type {Gender}
     * @memberof CreateUserDto
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'grade'?: string;
    /**
     * 
     * @type {Array<GetSchoolClassDto>}
     * @memberof CreateUserDto
     */
    'classes'?: Array<GetSchoolClassDto>;
    /**
     * 
     * @type {PlatformDto}
     * @memberof CreateUserDto
     */
    'platform'?: PlatformDto;
    /**
     * 
     * @type {number}
     * @memberof CreateUserDto
     */
    'level'?: number;
}
/**
 * 
 * @export
 * @interface DeleteStudentsDto
 */
export interface DeleteStudentsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteStudentsDto
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Device {
    Unknown = 'Unknown',
    Tablet = 'Tablet',
    Computer = 'Computer'
}

/**
 * 
 * @export
 * @interface ForgotPasswordDto
 */
export interface ForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Form {
    unknown = 'unknown',
    a = 'a',
    b = 'b'
}

/**
 * 
 * @export
 * @interface FormResultDto
 */
export interface FormResultDto {
    /**
     * 
     * @type {TestResultDto}
     * @memberof FormResultDto
     */
    'letters'?: TestResultDto;
    /**
     * 
     * @type {TestResultDto}
     * @memberof FormResultDto
     */
    'fantasy'?: TestResultDto;
    /**
     * 
     * @type {TestResultDto}
     * @memberof FormResultDto
     */
    'syllables'?: TestResultDto;
    /**
     * 
     * @type {TestResultDto}
     * @memberof FormResultDto
     */
    'words'?: TestResultDto;
    /**
     * 
     * @type {TestResultDto}
     * @memberof FormResultDto
     */
    'riddle'?: TestResultDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Gender {
    female = 'female',
    male = 'male'
}

/**
 * 
 * @export
 * @interface GetSchoolClassDto
 */
export interface GetSchoolClassDto {
    /**
     * 
     * @type {string}
     * @memberof GetSchoolClassDto
     */
    'id': string;
    /**
     * The unique name of the class
     * @type {string}
     * @memberof GetSchoolClassDto
     */
    'name': string;
    /**
     * The list of teachers for the class
     * @type {Array<string>}
     * @memberof GetSchoolClassDto
     */
    'teachers': Array<string>;
    /**
     * The list of students in the class
     * @type {Array<string>}
     * @memberof GetSchoolClassDto
     */
    'students': Array<string>;
}
/**
 * 
 * @export
 * @interface GetSchoolDto
 */
export interface GetSchoolDto {
    /**
     * 
     * @type {string}
     * @memberof GetSchoolDto
     */
    'id': string;
    /**
     * The name of the school
     * @type {string}
     * @memberof GetSchoolDto
     */
    'schoolName': string;
    /**
     * The unique identifier for the school
     * @type {string}
     * @memberof GetSchoolDto
     */
    'schoolId': string;
    /**
     * The kc group identifier
     * @type {string}
     * @memberof GetSchoolDto
     */
    'keycloakId': string;
    /**
     * The street address of the school
     * @type {string}
     * @memberof GetSchoolDto
     */
    'street': string;
    /**
     * The postal code of the school
     * @type {string}
     * @memberof GetSchoolDto
     */
    'postcode': string;
    /**
     * The city where the school is located
     * @type {string}
     * @memberof GetSchoolDto
     */
    'city': string;
    /**
     * The country where the school is located
     * @type {string}
     * @memberof GetSchoolDto
     */
    'country': string;
    /**
     * The URL of the school
     * @type {string}
     * @memberof GetSchoolDto
     */
    'url': string;
    /**
     * The unique slug for the school
     * @type {string}
     * @memberof GetSchoolDto
     */
    'slug': string;
    /**
     * The client type of the school
     * @type {string}
     * @memberof GetSchoolDto
     */
    'clientType': string;
    /**
     * The license expiration date for the school
     * @type {string}
     * @memberof GetSchoolDto
     */
    'licenseUntil': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSchoolDto
     */
    'isTutorialOnly': boolean;
    /**
     * User with admin rights for this school
     * @type {Array<string>}
     * @memberof GetSchoolDto
     */
    'admins': Array<string>;
    /**
     * The classes in the school
     * @type {Array<GetSchoolClassDto>}
     * @memberof GetSchoolDto
     */
    'classes': Array<GetSchoolClassDto>;
    /**
     * 
     * @type {number}
     * @memberof GetSchoolDto
     */
    'validTests'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSchoolDto
     */
    'sharePatients'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetSchoolDto
     */
    'classLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSchoolDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSchoolDto
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * 
 * @export
 * @interface KeycloakTokenDto
 */
export interface KeycloakTokenDto {
    /**
     * 
     * @type {string}
     * @memberof KeycloakTokenDto
     */
    'access_token': string;
    /**
     * 
     * @type {number}
     * @memberof KeycloakTokenDto
     */
    'expires_in': number;
    /**
     * 
     * @type {number}
     * @memberof KeycloakTokenDto
     */
    'refresh_expires_in': number;
    /**
     * 
     * @type {string}
     * @memberof KeycloakTokenDto
     */
    'refresh_token': string;
    /**
     * 
     * @type {string}
     * @memberof KeycloakTokenDto
     */
    'token_type': string;
    /**
     * 
     * @type {number}
     * @memberof KeycloakTokenDto
     */
    'not-before-policy': number;
    /**
     * 
     * @type {string}
     * @memberof KeycloakTokenDto
     */
    'session_state': string;
    /**
     * 
     * @type {string}
     * @memberof KeycloakTokenDto
     */
    'scope': string;
}
/**
 * 
 * @export
 * @interface LettersFantasyWordsResultDto
 */
export interface LettersFantasyWordsResultDto {
    /**
     * 
     * @type {string}
     * @memberof LettersFantasyWordsResultDto
     */
    'solution': string;
    /**
     * 
     * @type {string}
     * @memberof LettersFantasyWordsResultDto
     */
    'answer': string;
    /**
     * 
     * @type {number}
     * @memberof LettersFantasyWordsResultDto
     */
    'time': number;
    /**
     * 
     * @type {boolean}
     * @memberof LettersFantasyWordsResultDto
     */
    'isCorrect': boolean;
}
/**
 * 
 * @export
 * @interface LogEntryDto
 */
export interface LogEntryDto {
    /**
     * 
     * @type {UserDto}
     * @memberof LogEntryDto
     */
    'user': UserDto;
    /**
     * 
     * @type {string}
     * @memberof LogEntryDto
     */
    'severity': string;
    /**
     * 
     * @type {string}
     * @memberof LogEntryDto
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface PlatformDto
 */
export interface PlatformDto {
    /**
     * 
     * @type {string}
     * @memberof PlatformDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PlatformDto
     */
    'version': string;
    /**
     * 
     * @type {string}
     * @memberof PlatformDto
     */
    'layout': string;
    /**
     * 
     * @type {string}
     * @memberof PlatformDto
     */
    'os': string;
    /**
     * 
     * @type {string}
     * @memberof PlatformDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof PlatformDto
     */
    'ua': string;
    /**
     * 
     * @type {string}
     * @memberof PlatformDto
     */
    'product': string;
    /**
     * 
     * @type {string}
     * @memberof PlatformDto
     */
    'manufacturer': string;
}
/**
 * 
 * @export
 * @interface RefreshTokenDto
 */
export interface RefreshTokenDto {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface ResetPasswordDto
 */
export interface ResetPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ResultsDto
 */
export interface ResultsDto {
    /**
     * 
     * @type {string}
     * @memberof ResultsDto
     */
    'userId': string;
    /**
     * 
     * @type {number}
     * @memberof ResultsDto
     */
    'age'?: number;
    /**
     * 
     * @type {Gender}
     * @memberof ResultsDto
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof ResultsDto
     */
    'grade'?: string;
    /**
     * 
     * @type {FormResultDto}
     * @memberof ResultsDto
     */
    'a'?: FormResultDto;
    /**
     * 
     * @type {FormResultDto}
     * @memberof ResultsDto
     */
    'b'?: FormResultDto;
}
/**
 * 
 * @export
 * @interface RiddleResultDto
 */
export interface RiddleResultDto {
    /**
     * 
     * @type {string}
     * @memberof RiddleResultDto
     */
    'question': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RiddleResultDto
     */
    'answers': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RiddleResultDto
     */
    'time': number;
    /**
     * 
     * @type {boolean}
     * @memberof RiddleResultDto
     */
    'isCorrect': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Role {
    superadmin = 'superadmin',
    admin = 'admin',
    teacher = 'teacher',
    student = 'student',
    therapist = 'therapist',
    parent = 'parent'
}

/**
 * 
 * @export
 * @interface SyllablesResultDto
 */
export interface SyllablesResultDto {
    /**
     * 
     * @type {string}
     * @memberof SyllablesResultDto
     */
    'word': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof SyllablesResultDto
     */
    'answers': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof SyllablesResultDto
     */
    'time': number;
    /**
     * 
     * @type {boolean}
     * @memberof SyllablesResultDto
     */
    'isCorrect': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Test {
    unknown = 'unknown',
    letters = 'letters',
    fantasy = 'fantasy',
    syllables = 'syllables',
    words = 'words',
    riddle = 'riddle'
}

/**
 * 
 * @export
 * @interface TestResultDto
 */
export interface TestResultDto {
    /**
     * 
     * @type {Test}
     * @memberof TestResultDto
     */
    'test': Test;
    /**
     * 
     * @type {Form}
     * @memberof TestResultDto
     */
    'form': Form;
    /**
     * 
     * @type {Device}
     * @memberof TestResultDto
     */
    'device': Device;
    /**
     * 
     * @type {number}
     * @memberof TestResultDto
     */
    'questionsAnswered': number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDto
     */
    'overallQuestions': number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDto
     */
    'correctlyAnswered': number;
    /**
     * 
     * @type {number}
     * @memberof TestResultDto
     */
    'overallTime': number;
    /**
     * 
     * @type {boolean}
     * @memberof TestResultDto
     */
    'doneTutorial': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TestResultDto
     */
    'done'?: boolean;
    /**
     * 
     * @type {Array<LettersFantasyWordsResultDto | RiddleResultDto | SyllablesResultDto>}
     * @memberof TestResultDto
     */
    'answers': Array<LettersFantasyWordsResultDto | RiddleResultDto | SyllablesResultDto>;
}
/**
 * 
 * @export
 * @interface UpdateSchoolClassDto
 */
export interface UpdateSchoolClassDto {
    /**
     * Class Name
     * @type {string}
     * @memberof UpdateSchoolClassDto
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSchoolClassDto
     */
    'teachers'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSchoolClassDto
     */
    'students'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateSchoolDto
 */
export interface UpdateSchoolDto {
    /**
     * The name of the school
     * @type {string}
     * @memberof UpdateSchoolDto
     */
    'schoolName'?: string;
    /**
     * The unique identifier for the school
     * @type {string}
     * @memberof UpdateSchoolDto
     */
    'schoolId'?: string;
    /**
     * The kc identifier
     * @type {string}
     * @memberof UpdateSchoolDto
     */
    'keycloakId'?: string;
    /**
     * The street address of the school
     * @type {string}
     * @memberof UpdateSchoolDto
     */
    'street'?: string;
    /**
     * The postal code of the school
     * @type {string}
     * @memberof UpdateSchoolDto
     */
    'postcode'?: string;
    /**
     * The city where the school is located
     * @type {string}
     * @memberof UpdateSchoolDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchoolDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchoolDto
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchoolDto
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSchoolDto
     */
    'clientType'?: string;
    /**
     * The license expiration date for the school
     * @type {string}
     * @memberof UpdateSchoolDto
     */
    'licenseUntil'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSchoolDto
     */
    'isTutorialOnly'?: boolean;
    /**
     * The admin users kcId for the school
     * @type {Array<string>}
     * @memberof UpdateSchoolDto
     */
    'admins'?: Array<string>;
    /**
     * The classes in the school
     * @type {Array<CreateSchoolClassDto>}
     * @memberof UpdateSchoolDto
     */
    'classes'?: Array<CreateSchoolClassDto>;
    /**
     * 
     * @type {number}
     * @memberof UpdateSchoolDto
     */
    'validTests'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSchoolDto
     */
    'sharePatients'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateSchoolDto
     */
    'classLimit'?: number;
}
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'lastname'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'password'?: string;
    /**
     * 
     * @type {Role}
     * @memberof UpdateUserDto
     */
    'role'?: Role;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'schoolId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserDto
     */
    'studentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'studentPassword'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'doneA'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'doneB'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'enableA'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'enableB'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'firstLogin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'downloadedA'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'downloadedB'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserDto
     */
    'age'?: number;
    /**
     * 
     * @type {Gender}
     * @memberof UpdateUserDto
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'grade'?: string;
    /**
     * 
     * @type {Array<GetSchoolClassDto>}
     * @memberof UpdateUserDto
     */
    'classes'?: Array<GetSchoolClassDto>;
    /**
     * 
     * @type {PlatformDto}
     * @memberof UpdateUserDto
     */
    'platform'?: PlatformDto;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserDto
     */
    'level'?: number;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastname': string;
    /**
     * 
     * @type {Role}
     * @memberof UserDto
     */
    'role': Role;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'schoolId': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'studentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'studentPassword'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'doneA': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'doneB': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'enableA'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'enableB'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'age'?: number;
    /**
     * 
     * @type {Gender}
     * @memberof UserDto
     */
    'gender'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'grade'?: string;
    /**
     * 
     * @type {Array<GetSchoolClassDto>}
     * @memberof UserDto
     */
    'classes': Array<GetSchoolClassDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    'groups'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'firstLogin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'downloadedA'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'downloadedB'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'schoolName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'street'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'postcode'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'licenseUntil'?: string;
    /**
     * 
     * @type {Client}
     * @memberof UserDto
     */
    'clientType'?: Client;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'url'?: string;
    /**
     * 
     * @type {PlatformDto}
     * @memberof UserDto
     */
    'platform'?: PlatformDto;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'slug'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isTutorialOnly'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'validTests'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'level'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'sharePatients'?: boolean;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminResetsPassword: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authControllerAdminResetsPassword', 'id', id)
            const localVarPath = `/auth/reset-password/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword: async (forgotPasswordDto: ForgotPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordDto' is not null or undefined
            assertParamExists('authControllerForgotPassword', 'forgotPasswordDto', forgotPasswordDto)
            const localVarPath = `/auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetCurrentUser: async (schoolId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('authControllerGetCurrentUser', 'schoolId', schoolId)
            const localVarPath = `/auth/{schoolId}/current-user`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenDto} refreshTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshToken: async (refreshTokenDto: RefreshTokenDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenDto' is not null or undefined
            assertParamExists('authControllerRefreshToken', 'refreshTokenDto', refreshTokenDto)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPassword: async (resetPasswordDto: ResetPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordDto' is not null or undefined
            assertParamExists('authControllerResetPassword', 'resetPasswordDto', resetPasswordDto)
            const localVarPath = `/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAdminResetsPassword(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAdminResetsPassword(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerForgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerForgotPassword(forgotPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetCurrentUser(schoolId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGetCurrentUser(schoolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KeycloakTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshTokenDto} refreshTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefreshToken(refreshTokenDto: RefreshTokenDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRefreshToken(refreshTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerResetPassword(resetPasswordDto: ResetPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerResetPassword(resetPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAdminResetsPassword(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerAdminResetsPassword(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerForgotPassword(forgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetCurrentUser(schoolId: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.authControllerGetCurrentUser(schoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(loginDto: LoginDto, options?: any): AxiosPromise<KeycloakTokenDto> {
            return localVarFp.authControllerLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenDto} refreshTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshToken(refreshTokenDto: RefreshTokenDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerRefreshToken(refreshTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPassword(resetPasswordDto: ResetPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerResetPassword(resetPasswordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerAdminResetsPassword(id: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerAdminResetsPassword(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordDto} forgotPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerForgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerForgotPassword(forgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} schoolId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerGetCurrentUser(schoolId: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerGetCurrentUser(schoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerLogin(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerLogin(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshTokenDto} refreshTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerRefreshToken(refreshTokenDto: RefreshTokenDto, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerRefreshToken(refreshTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordDto} resetPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authControllerResetPassword(resetPasswordDto: ResetPasswordDto, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authControllerResetPassword(resetPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new user and add as admin to school
         * @param {string} schoolId 
         * @param {CreateAdminDto} createAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerAddNewAdmin: async (schoolId: string, createAdminDto: CreateAdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerAddNewAdmin', 'schoolId', schoolId)
            // verify required parameter 'createAdminDto' is not null or undefined
            assertParamExists('clientsControllerAddNewAdmin', 'createAdminDto', createAdminDto)
            const localVarPath = `/clients/{schoolId}/admin/new`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new Lesikus client (school, therapy, parent)
         * @param {CreateSchoolDto} createSchoolDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerCreate: async (createSchoolDto: CreateSchoolDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSchoolDto' is not null or undefined
            assertParamExists('clientsControllerCreate', 'createSchoolDto', createSchoolDto)
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSchoolDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDecreaseValidTests: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('clientsControllerDecreaseValidTests', 'slug', slug)
            const localVarPath = `/clients/valid-tests/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all test results from one class by teacher
         * @param {string} schoolId 
         * @param {string} className 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeleteAllTestResultsFromMyClass: async (schoolId: string, className: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerDeleteAllTestResultsFromMyClass', 'schoolId', schoolId)
            // verify required parameter 'className' is not null or undefined
            assertParamExists('clientsControllerDeleteAllTestResultsFromMyClass', 'className', className)
            const localVarPath = `/clients/teacher/{schoolId}/tests/grade/{className}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)))
                .replace(`{${"className"}}`, encodeURIComponent(String(className)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all test results from one student by teacher
         * @param {string} schoolId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeleteAllTestResultsFromStudent: async (schoolId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerDeleteAllTestResultsFromStudent', 'schoolId', schoolId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientsControllerDeleteAllTestResultsFromStudent', 'id', id)
            const localVarPath = `/clients/teacher/{schoolId}/tests/{id}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete client, all members and all students of school by kcId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeleteClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientsControllerDeleteClient', 'id', id)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all patients of a Therapist
         * @param {string} schoolId 
         * @param {string} grade 
         * @param {DeleteStudentsDto} deleteStudentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeletePatientsOfTherapist: async (schoolId: string, grade: string, deleteStudentsDto: DeleteStudentsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerDeletePatientsOfTherapist', 'schoolId', schoolId)
            // verify required parameter 'grade' is not null or undefined
            assertParamExists('clientsControllerDeletePatientsOfTherapist', 'grade', grade)
            // verify required parameter 'deleteStudentsDto' is not null or undefined
            assertParamExists('clientsControllerDeletePatientsOfTherapist', 'deleteStudentsDto', deleteStudentsDto)
            const localVarPath = `/clients/{schoolId}/patients/{grade}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)))
                .replace(`{${"grade"}}`, encodeURIComponent(String(grade)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteStudentsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all students and their test results from school
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeleteStudentsOfSchool: async (schoolId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerDeleteStudentsOfSchool', 'schoolId', schoolId)
            const localVarPath = `/clients/{schoolId}/students`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all students and their test results from class
         * @param {string} schoolId 
         * @param {string} grade 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeleteStudentsOfSchoolClass: async (schoolId: string, grade: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerDeleteStudentsOfSchoolClass', 'schoolId', schoolId)
            // verify required parameter 'grade' is not null or undefined
            assertParamExists('clientsControllerDeleteStudentsOfSchoolClass', 'grade', grade)
            const localVarPath = `/clients/{schoolId}/students/{grade}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)))
                .replace(`{${"grade"}}`, encodeURIComponent(String(grade)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete class (students + results) by superadmin or school user with admin rights
         * @param {string} schoolId 
         * @param {string} grade 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeleteStudentsOfSchoolClassBySuperadmin: async (schoolId: string, grade: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerDeleteStudentsOfSchoolClassBySuperadmin', 'schoolId', schoolId)
            // verify required parameter 'grade' is not null or undefined
            assertParamExists('clientsControllerDeleteStudentsOfSchoolClassBySuperadmin', 'grade', grade)
            const localVarPath = `/clients/superadmin/{schoolId}/students/{grade}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)))
                .replace(`{${"grade"}}`, encodeURIComponent(String(grade)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get AVV for client
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDownloadAVV: async (schoolId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerDownloadAVV', 'schoolId', schoolId)
            const localVarPath = `/clients/avv/{schoolId}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get First Steps Information for Admins
         * @param {string} schoolId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDownloadAdminManual: async (schoolId: string, code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerDownloadAdminManual', 'schoolId', schoolId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('clientsControllerDownloadAdminManual', 'code', code)
            const localVarPath = `/clients/manual/{schoolId}/admin/{code}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)))
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get First Steps Information for Teacher/Therapist
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDownloadTeacherTherapistManual: async (schoolId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerDownloadTeacherTherapistManual', 'schoolId', schoolId)
            const localVarPath = `/clients/manual/{schoolId}/teacher-or-therapists`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} schoolId 
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindAdmins: async (schoolId: string, ids: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerFindAdmins', 'schoolId', schoolId)
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('clientsControllerFindAdmins', 'ids', ids)
            const localVarPath = `/clients/{schoolId}/admins`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} schoolSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindBasicSchoolInfo: async (schoolSlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolSlug' is not null or undefined
            assertParamExists('clientsControllerFindBasicSchoolInfo', 'schoolSlug', schoolSlug)
            const localVarPath = `/clients/{schoolSlug}/basic-info`
                .replace(`{${"schoolSlug"}}`, encodeURIComponent(String(schoolSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindOne: async (schoolId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerFindOne', 'schoolId', schoolId)
            const localVarPath = `/clients/{schoolId}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerGetAllContacts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients/export/contacts/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add/Remove user with admin-rights
         * @param {string} schoolId 
         * @param {AdminDto} adminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerRemoveAsAdmin: async (schoolId: string, adminDto: AdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsControllerRemoveAsAdmin', 'schoolId', schoolId)
            // verify required parameter 'adminDto' is not null or undefined
            assertParamExists('clientsControllerRemoveAsAdmin', 'adminDto', adminDto)
            const localVarPath = `/clients/{schoolId}/admin`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSchoolDto} updateSchoolDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerUpdate: async (id: string, updateSchoolDto: UpdateSchoolDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientsControllerUpdate', 'id', id)
            // verify required parameter 'updateSchoolDto' is not null or undefined
            assertParamExists('clientsControllerUpdate', 'updateSchoolDto', updateSchoolDto)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSchoolDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new user and add as admin to school
         * @param {string} schoolId 
         * @param {CreateAdminDto} createAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerAddNewAdmin(schoolId: string, createAdminDto: CreateAdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSchoolDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerAddNewAdmin(schoolId, createAdminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new Lesikus client (school, therapy, parent)
         * @param {CreateSchoolDto} createSchoolDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerCreate(createSchoolDto: CreateSchoolDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSchoolDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerCreate(createSchoolDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerDecreaseValidTests(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerDecreaseValidTests(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all test results from one class by teacher
         * @param {string} schoolId 
         * @param {string} className 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerDeleteAllTestResultsFromMyClass(schoolId: string, className: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerDeleteAllTestResultsFromMyClass(schoolId, className, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all test results from one student by teacher
         * @param {string} schoolId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerDeleteAllTestResultsFromStudent(schoolId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerDeleteAllTestResultsFromStudent(schoolId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete client, all members and all students of school by kcId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerDeleteClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerDeleteClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all patients of a Therapist
         * @param {string} schoolId 
         * @param {string} grade 
         * @param {DeleteStudentsDto} deleteStudentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerDeletePatientsOfTherapist(schoolId: string, grade: string, deleteStudentsDto: DeleteStudentsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerDeletePatientsOfTherapist(schoolId, grade, deleteStudentsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all students and their test results from school
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerDeleteStudentsOfSchool(schoolId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerDeleteStudentsOfSchool(schoolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all students and their test results from class
         * @param {string} schoolId 
         * @param {string} grade 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerDeleteStudentsOfSchoolClass(schoolId: string, grade: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerDeleteStudentsOfSchoolClass(schoolId, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete class (students + results) by superadmin or school user with admin rights
         * @param {string} schoolId 
         * @param {string} grade 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerDeleteStudentsOfSchoolClassBySuperadmin(schoolId: string, grade: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerDeleteStudentsOfSchoolClassBySuperadmin(schoolId, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get AVV for client
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerDownloadAVV(schoolId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerDownloadAVV(schoolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get First Steps Information for Admins
         * @param {string} schoolId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerDownloadAdminManual(schoolId: string, code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerDownloadAdminManual(schoolId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get First Steps Information for Teacher/Therapist
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerDownloadTeacherTherapistManual(schoolId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerDownloadTeacherTherapistManual(schoolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} schoolId 
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerFindAdmins(schoolId: string, ids: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerFindAdmins(schoolId, ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetSchoolDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} schoolSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerFindBasicSchoolInfo(schoolSlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicSchoolDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerFindBasicSchoolInfo(schoolSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerFindOne(schoolId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerFindOne(schoolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerGetAllContacts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerGetAllContacts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add/Remove user with admin-rights
         * @param {string} schoolId 
         * @param {AdminDto} adminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerRemoveAsAdmin(schoolId: string, adminDto: AdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSchoolDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerRemoveAsAdmin(schoolId, adminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSchoolDto} updateSchoolDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsControllerUpdate(id: string, updateSchoolDto: UpdateSchoolDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSchoolDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsControllerUpdate(id, updateSchoolDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new user and add as admin to school
         * @param {string} schoolId 
         * @param {CreateAdminDto} createAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerAddNewAdmin(schoolId: string, createAdminDto: CreateAdminDto, options?: any): AxiosPromise<GetSchoolDto> {
            return localVarFp.clientsControllerAddNewAdmin(schoolId, createAdminDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new Lesikus client (school, therapy, parent)
         * @param {CreateSchoolDto} createSchoolDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerCreate(createSchoolDto: CreateSchoolDto, options?: any): AxiosPromise<GetSchoolDto> {
            return localVarFp.clientsControllerCreate(createSchoolDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDecreaseValidTests(slug: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerDecreaseValidTests(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all test results from one class by teacher
         * @param {string} schoolId 
         * @param {string} className 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeleteAllTestResultsFromMyClass(schoolId: string, className: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerDeleteAllTestResultsFromMyClass(schoolId, className, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all test results from one student by teacher
         * @param {string} schoolId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeleteAllTestResultsFromStudent(schoolId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerDeleteAllTestResultsFromStudent(schoolId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete client, all members and all students of school by kcId
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeleteClient(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerDeleteClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all patients of a Therapist
         * @param {string} schoolId 
         * @param {string} grade 
         * @param {DeleteStudentsDto} deleteStudentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeletePatientsOfTherapist(schoolId: string, grade: string, deleteStudentsDto: DeleteStudentsDto, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerDeletePatientsOfTherapist(schoolId, grade, deleteStudentsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all students and their test results from school
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeleteStudentsOfSchool(schoolId: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerDeleteStudentsOfSchool(schoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all students and their test results from class
         * @param {string} schoolId 
         * @param {string} grade 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeleteStudentsOfSchoolClass(schoolId: string, grade: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerDeleteStudentsOfSchoolClass(schoolId, grade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete class (students + results) by superadmin or school user with admin rights
         * @param {string} schoolId 
         * @param {string} grade 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDeleteStudentsOfSchoolClassBySuperadmin(schoolId: string, grade: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerDeleteStudentsOfSchoolClassBySuperadmin(schoolId, grade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get AVV for client
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDownloadAVV(schoolId: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerDownloadAVV(schoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get First Steps Information for Admins
         * @param {string} schoolId 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDownloadAdminManual(schoolId: string, code: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerDownloadAdminManual(schoolId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get First Steps Information for Teacher/Therapist
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerDownloadTeacherTherapistManual(schoolId: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerDownloadTeacherTherapistManual(schoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} schoolId 
         * @param {string} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindAdmins(schoolId: string, ids: string, options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.clientsControllerFindAdmins(schoolId, ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindAll(options?: any): AxiosPromise<Array<GetSchoolDto>> {
            return localVarFp.clientsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} schoolSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindBasicSchoolInfo(schoolSlug: string, options?: any): AxiosPromise<BasicSchoolDto> {
            return localVarFp.clientsControllerFindBasicSchoolInfo(schoolSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerFindOne(schoolId: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerFindOne(schoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerGetAllContacts(options?: any): AxiosPromise<void> {
            return localVarFp.clientsControllerGetAllContacts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add/Remove user with admin-rights
         * @param {string} schoolId 
         * @param {AdminDto} adminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerRemoveAsAdmin(schoolId: string, adminDto: AdminDto, options?: any): AxiosPromise<GetSchoolDto> {
            return localVarFp.clientsControllerRemoveAsAdmin(schoolId, adminDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSchoolDto} updateSchoolDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsControllerUpdate(id: string, updateSchoolDto: UpdateSchoolDto, options?: any): AxiosPromise<GetSchoolDto> {
            return localVarFp.clientsControllerUpdate(id, updateSchoolDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * 
     * @summary Create new user and add as admin to school
     * @param {string} schoolId 
     * @param {CreateAdminDto} createAdminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerAddNewAdmin(schoolId: string, createAdminDto: CreateAdminDto, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerAddNewAdmin(schoolId, createAdminDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new Lesikus client (school, therapy, parent)
     * @param {CreateSchoolDto} createSchoolDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerCreate(createSchoolDto: CreateSchoolDto, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerCreate(createSchoolDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerDecreaseValidTests(slug: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerDecreaseValidTests(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all test results from one class by teacher
     * @param {string} schoolId 
     * @param {string} className 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerDeleteAllTestResultsFromMyClass(schoolId: string, className: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerDeleteAllTestResultsFromMyClass(schoolId, className, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all test results from one student by teacher
     * @param {string} schoolId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerDeleteAllTestResultsFromStudent(schoolId: string, id: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerDeleteAllTestResultsFromStudent(schoolId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete client, all members and all students of school by kcId
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerDeleteClient(id: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerDeleteClient(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all patients of a Therapist
     * @param {string} schoolId 
     * @param {string} grade 
     * @param {DeleteStudentsDto} deleteStudentsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerDeletePatientsOfTherapist(schoolId: string, grade: string, deleteStudentsDto: DeleteStudentsDto, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerDeletePatientsOfTherapist(schoolId, grade, deleteStudentsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all students and their test results from school
     * @param {string} schoolId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerDeleteStudentsOfSchool(schoolId: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerDeleteStudentsOfSchool(schoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all students and their test results from class
     * @param {string} schoolId 
     * @param {string} grade 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerDeleteStudentsOfSchoolClass(schoolId: string, grade: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerDeleteStudentsOfSchoolClass(schoolId, grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete class (students + results) by superadmin or school user with admin rights
     * @param {string} schoolId 
     * @param {string} grade 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerDeleteStudentsOfSchoolClassBySuperadmin(schoolId: string, grade: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerDeleteStudentsOfSchoolClassBySuperadmin(schoolId, grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get AVV for client
     * @param {string} schoolId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerDownloadAVV(schoolId: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerDownloadAVV(schoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get First Steps Information for Admins
     * @param {string} schoolId 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerDownloadAdminManual(schoolId: string, code: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerDownloadAdminManual(schoolId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get First Steps Information for Teacher/Therapist
     * @param {string} schoolId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerDownloadTeacherTherapistManual(schoolId: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerDownloadTeacherTherapistManual(schoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} schoolId 
     * @param {string} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerFindAdmins(schoolId: string, ids: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerFindAdmins(schoolId, ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerFindAll(options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} schoolSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerFindBasicSchoolInfo(schoolSlug: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerFindBasicSchoolInfo(schoolSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} schoolId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerFindOne(schoolId: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerFindOne(schoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerGetAllContacts(options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerGetAllContacts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add/Remove user with admin-rights
     * @param {string} schoolId 
     * @param {AdminDto} adminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerRemoveAsAdmin(schoolId: string, adminDto: AdminDto, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerRemoveAsAdmin(schoolId, adminDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateSchoolDto} updateSchoolDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientsControllerUpdate(id: string, updateSchoolDto: UpdateSchoolDto, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientsControllerUpdate(id, updateSchoolDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsDBApi - axios parameter creator
 * @export
 */
export const ClientsDBApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create all school documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMongoControllerCreateAllSchoolDocuments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients/schools/create/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new class for school
         * @param {string} schoolId 
         * @param {CreateSchoolClassDto} createSchoolClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMongoControllerCreateClass: async (schoolId: string, createSchoolClassDto: CreateSchoolClassDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsMongoControllerCreateClass', 'schoolId', schoolId)
            // verify required parameter 'createSchoolClassDto' is not null or undefined
            assertParamExists('clientsMongoControllerCreateClass', 'createSchoolClassDto', createSchoolClassDto)
            const localVarPath = `/clients/schools/{schoolId}/class`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSchoolClassDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all classes from school
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMongoControllerFindAllClasses: async (schoolId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsMongoControllerFindAllClasses', 'schoolId', schoolId)
            const localVarPath = `/clients/schools/{schoolId}/classes`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a class from school
         * @param {string} schoolId 
         * @param {string} className 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMongoControllerRemoveClass: async (schoolId: string, className: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsMongoControllerRemoveClass', 'schoolId', schoolId)
            // verify required parameter 'className' is not null or undefined
            assertParamExists('clientsMongoControllerRemoveClass', 'className', className)
            const localVarPath = `/clients/schools/{schoolId}/{className}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)))
                .replace(`{${"className"}}`, encodeURIComponent(String(className)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the class name + usernames + classes attr
         * @param {string} schoolId 
         * @param {string} classId 
         * @param {UpdateSchoolClassDto} updateSchoolClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMongoControllerUpdateClassName: async (schoolId: string, classId: string, updateSchoolClassDto: UpdateSchoolClassDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsMongoControllerUpdateClassName', 'schoolId', schoolId)
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('clientsMongoControllerUpdateClassName', 'classId', classId)
            // verify required parameter 'updateSchoolClassDto' is not null or undefined
            assertParamExists('clientsMongoControllerUpdateClassName', 'updateSchoolClassDto', updateSchoolClassDto)
            const localVarPath = `/clients/schools/{schoolId}/{classId}/name`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)))
                .replace(`{${"classId"}}`, encodeURIComponent(String(classId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSchoolClassDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the classes for a teacher
         * @param {string} schoolId 
         * @param {string} teacherId 
         * @param {Array<GetSchoolClassDto>} getSchoolClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMongoControllerUpdateClassesForTeacher: async (schoolId: string, teacherId: string, getSchoolClassDto: Array<GetSchoolClassDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('clientsMongoControllerUpdateClassesForTeacher', 'schoolId', schoolId)
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('clientsMongoControllerUpdateClassesForTeacher', 'teacherId', teacherId)
            // verify required parameter 'getSchoolClassDto' is not null or undefined
            assertParamExists('clientsMongoControllerUpdateClassesForTeacher', 'getSchoolClassDto', getSchoolClassDto)
            const localVarPath = `/clients/schools/{schoolId}/classes/teacher/{teacherId}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)))
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSchoolClassDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsDBApi - functional programming interface
 * @export
 */
export const ClientsDBApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsDBApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create all school documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsMongoControllerCreateAllSchoolDocuments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsMongoControllerCreateAllSchoolDocuments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new class for school
         * @param {string} schoolId 
         * @param {CreateSchoolClassDto} createSchoolClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsMongoControllerCreateClass(schoolId: string, createSchoolClassDto: CreateSchoolClassDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSchoolClassDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsMongoControllerCreateClass(schoolId, createSchoolClassDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all classes from school
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsMongoControllerFindAllClasses(schoolId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetSchoolClassDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsMongoControllerFindAllClasses(schoolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a class from school
         * @param {string} schoolId 
         * @param {string} className 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsMongoControllerRemoveClass(schoolId: string, className: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsMongoControllerRemoveClass(schoolId, className, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the class name + usernames + classes attr
         * @param {string} schoolId 
         * @param {string} classId 
         * @param {UpdateSchoolClassDto} updateSchoolClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsMongoControllerUpdateClassName(schoolId: string, classId: string, updateSchoolClassDto: UpdateSchoolClassDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSchoolClassDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsMongoControllerUpdateClassName(schoolId, classId, updateSchoolClassDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the classes for a teacher
         * @param {string} schoolId 
         * @param {string} teacherId 
         * @param {Array<GetSchoolClassDto>} getSchoolClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsMongoControllerUpdateClassesForTeacher(schoolId: string, teacherId: string, getSchoolClassDto: Array<GetSchoolClassDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetSchoolClassDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientsMongoControllerUpdateClassesForTeacher(schoolId, teacherId, getSchoolClassDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsDBApi - factory interface
 * @export
 */
export const ClientsDBApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsDBApiFp(configuration)
    return {
        /**
         * 
         * @summary Create all school documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMongoControllerCreateAllSchoolDocuments(options?: any): AxiosPromise<void> {
            return localVarFp.clientsMongoControllerCreateAllSchoolDocuments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new class for school
         * @param {string} schoolId 
         * @param {CreateSchoolClassDto} createSchoolClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMongoControllerCreateClass(schoolId: string, createSchoolClassDto: CreateSchoolClassDto, options?: any): AxiosPromise<GetSchoolClassDto> {
            return localVarFp.clientsMongoControllerCreateClass(schoolId, createSchoolClassDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all classes from school
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMongoControllerFindAllClasses(schoolId: string, options?: any): AxiosPromise<Array<GetSchoolClassDto>> {
            return localVarFp.clientsMongoControllerFindAllClasses(schoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a class from school
         * @param {string} schoolId 
         * @param {string} className 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMongoControllerRemoveClass(schoolId: string, className: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientsMongoControllerRemoveClass(schoolId, className, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the class name + usernames + classes attr
         * @param {string} schoolId 
         * @param {string} classId 
         * @param {UpdateSchoolClassDto} updateSchoolClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMongoControllerUpdateClassName(schoolId: string, classId: string, updateSchoolClassDto: UpdateSchoolClassDto, options?: any): AxiosPromise<GetSchoolClassDto> {
            return localVarFp.clientsMongoControllerUpdateClassName(schoolId, classId, updateSchoolClassDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the classes for a teacher
         * @param {string} schoolId 
         * @param {string} teacherId 
         * @param {Array<GetSchoolClassDto>} getSchoolClassDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsMongoControllerUpdateClassesForTeacher(schoolId: string, teacherId: string, getSchoolClassDto: Array<GetSchoolClassDto>, options?: any): AxiosPromise<Array<GetSchoolClassDto>> {
            return localVarFp.clientsMongoControllerUpdateClassesForTeacher(schoolId, teacherId, getSchoolClassDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientsDBApi - object-oriented interface
 * @export
 * @class ClientsDBApi
 * @extends {BaseAPI}
 */
export class ClientsDBApi extends BaseAPI {
    /**
     * 
     * @summary Create all school documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsDBApi
     */
    public clientsMongoControllerCreateAllSchoolDocuments(options?: AxiosRequestConfig) {
        return ClientsDBApiFp(this.configuration).clientsMongoControllerCreateAllSchoolDocuments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new class for school
     * @param {string} schoolId 
     * @param {CreateSchoolClassDto} createSchoolClassDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsDBApi
     */
    public clientsMongoControllerCreateClass(schoolId: string, createSchoolClassDto: CreateSchoolClassDto, options?: AxiosRequestConfig) {
        return ClientsDBApiFp(this.configuration).clientsMongoControllerCreateClass(schoolId, createSchoolClassDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all classes from school
     * @param {string} schoolId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsDBApi
     */
    public clientsMongoControllerFindAllClasses(schoolId: string, options?: AxiosRequestConfig) {
        return ClientsDBApiFp(this.configuration).clientsMongoControllerFindAllClasses(schoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a class from school
     * @param {string} schoolId 
     * @param {string} className 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsDBApi
     */
    public clientsMongoControllerRemoveClass(schoolId: string, className: string, options?: AxiosRequestConfig) {
        return ClientsDBApiFp(this.configuration).clientsMongoControllerRemoveClass(schoolId, className, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the class name + usernames + classes attr
     * @param {string} schoolId 
     * @param {string} classId 
     * @param {UpdateSchoolClassDto} updateSchoolClassDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsDBApi
     */
    public clientsMongoControllerUpdateClassName(schoolId: string, classId: string, updateSchoolClassDto: UpdateSchoolClassDto, options?: AxiosRequestConfig) {
        return ClientsDBApiFp(this.configuration).clientsMongoControllerUpdateClassName(schoolId, classId, updateSchoolClassDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the classes for a teacher
     * @param {string} schoolId 
     * @param {string} teacherId 
     * @param {Array<GetSchoolClassDto>} getSchoolClassDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsDBApi
     */
    public clientsMongoControllerUpdateClassesForTeacher(schoolId: string, teacherId: string, getSchoolClassDto: Array<GetSchoolClassDto>, options?: AxiosRequestConfig) {
        return ClientsDBApiFp(this.configuration).clientsMongoControllerUpdateClassesForTeacher(schoolId, teacherId, getSchoolClassDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new log-entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerCreateLogEntry: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all log-entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all log-entries for one user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerFindAllForUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logControllerFindAllForUser', 'id', id)
            const localVarPath = `/log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new log-entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerCreateLogEntry(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerCreateLogEntry(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all log-entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all log-entries for one user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logControllerFindAllForUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogEntryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logControllerFindAllForUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.healthControllerCheck(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new log-entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerCreateLogEntry(options?: any): AxiosPromise<void> {
            return localVarFp.logControllerCreateLogEntry(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all log-entries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerFindAll(options?: any): AxiosPromise<LogEntryDto> {
            return localVarFp.logControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all log-entries for one user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logControllerFindAllForUser(id: string, options?: any): AxiosPromise<Array<LogEntryDto>> {
            return localVarFp.logControllerFindAllForUser(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheck(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerCheck(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new log-entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logControllerCreateLogEntry(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).logControllerCreateLogEntry(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all log-entries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logControllerFindAll(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).logControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all log-entries for one user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logControllerFindAllForUser(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).logControllerFindAllForUser(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailApi - axios parameter creator
 * @export
 */
export const MailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} template 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerSendMail: async (template: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'template' is not null or undefined
            assertParamExists('mailControllerSendMail', 'template', template)
            const localVarPath = `/mail/{template}`
                .replace(`{${"template"}}`, encodeURIComponent(String(template)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailApi - functional programming interface
 * @export
 */
export const MailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} template 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailControllerSendMail(template: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailControllerSendMail(template, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailApi - factory interface
 * @export
 */
export const MailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailApiFp(configuration)
    return {
        /**
         * 
         * @param {string} template 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailControllerSendMail(template: string, options?: any): AxiosPromise<string> {
            return localVarFp.mailControllerSendMail(template, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailApi - object-oriented interface
 * @export
 * @class MailApi
 * @extends {BaseAPI}
 */
export class MailApi extends BaseAPI {
    /**
     * 
     * @param {string} template 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    public mailControllerSendMail(template: string, options?: AxiosRequestConfig) {
        return MailApiFp(this.configuration).mailControllerSendMail(template, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PDFApi - axios parameter creator
 * @export
 */
export const PDFApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Classlist PDF
         * @param {string} schoolId 
         * @param {string} className 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfControllerClassList: async (schoolId: string, className: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('pdfControllerClassList', 'schoolId', schoolId)
            // verify required parameter 'className' is not null or undefined
            assertParamExists('pdfControllerClassList', 'className', className)
            const localVarPath = `/pdf/classList/{schoolId}/codes/{className}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)))
                .replace(`{${"className"}}`, encodeURIComponent(String(className)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary LEO-Manual/Practice PDF
         * @param {string} book 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfControllerDownloadBook: async (book: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('pdfControllerDownloadBook', 'book', book)
            const localVarPath = `/pdf/download/school/{book}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Evaluate Test Result of User
         * @param {string} id 
         * @param {string} form 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfControllerEvaluateStudent: async (id: string, form: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pdfControllerEvaluateStudent', 'id', id)
            // verify required parameter 'form' is not null or undefined
            assertParamExists('pdfControllerEvaluateStudent', 'form', form)
            const localVarPath = `/pdf/{id}/{form}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"form"}}`, encodeURIComponent(String(form)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Evaluate Test Result of User
         * @param {string} form 
         * @param {string} grade 
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfControllerGetClassEvaluation: async (form: string, grade: string, schoolId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('pdfControllerGetClassEvaluation', 'form', form)
            // verify required parameter 'grade' is not null or undefined
            assertParamExists('pdfControllerGetClassEvaluation', 'grade', grade)
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('pdfControllerGetClassEvaluation', 'schoolId', schoolId)
            const localVarPath = `/pdf/class-evaluation/{schoolId}/{grade}/{form}`
                .replace(`{${"form"}}`, encodeURIComponent(String(form)))
                .replace(`{${"grade"}}`, encodeURIComponent(String(grade)))
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Code for Single Student PDF
         * @param {string} schoolId 
         * @param {string} className 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfControllerSingleCode: async (schoolId: string, className: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('pdfControllerSingleCode', 'schoolId', schoolId)
            // verify required parameter 'className' is not null or undefined
            assertParamExists('pdfControllerSingleCode', 'className', className)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pdfControllerSingleCode', 'id', id)
            const localVarPath = `/pdf/classList/{schoolId}/{className}/code/{id}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)))
                .replace(`{${"className"}}`, encodeURIComponent(String(className)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PDFApi - functional programming interface
 * @export
 */
export const PDFApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PDFApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Classlist PDF
         * @param {string} schoolId 
         * @param {string} className 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pdfControllerClassList(schoolId: string, className: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pdfControllerClassList(schoolId, className, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary LEO-Manual/Practice PDF
         * @param {string} book 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pdfControllerDownloadBook(book: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pdfControllerDownloadBook(book, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Evaluate Test Result of User
         * @param {string} id 
         * @param {string} form 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pdfControllerEvaluateStudent(id: string, form: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pdfControllerEvaluateStudent(id, form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Evaluate Test Result of User
         * @param {string} form 
         * @param {string} grade 
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pdfControllerGetClassEvaluation(form: string, grade: string, schoolId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pdfControllerGetClassEvaluation(form, grade, schoolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Code for Single Student PDF
         * @param {string} schoolId 
         * @param {string} className 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pdfControllerSingleCode(schoolId: string, className: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pdfControllerSingleCode(schoolId, className, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PDFApi - factory interface
 * @export
 */
export const PDFApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PDFApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Classlist PDF
         * @param {string} schoolId 
         * @param {string} className 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfControllerClassList(schoolId: string, className: string, options?: any): AxiosPromise<void> {
            return localVarFp.pdfControllerClassList(schoolId, className, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary LEO-Manual/Practice PDF
         * @param {string} book 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfControllerDownloadBook(book: string, options?: any): AxiosPromise<void> {
            return localVarFp.pdfControllerDownloadBook(book, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Evaluate Test Result of User
         * @param {string} id 
         * @param {string} form 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfControllerEvaluateStudent(id: string, form: string, options?: any): AxiosPromise<void> {
            return localVarFp.pdfControllerEvaluateStudent(id, form, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Evaluate Test Result of User
         * @param {string} form 
         * @param {string} grade 
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfControllerGetClassEvaluation(form: string, grade: string, schoolId: string, options?: any): AxiosPromise<void> {
            return localVarFp.pdfControllerGetClassEvaluation(form, grade, schoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Code for Single Student PDF
         * @param {string} schoolId 
         * @param {string} className 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfControllerSingleCode(schoolId: string, className: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.pdfControllerSingleCode(schoolId, className, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PDFApi - object-oriented interface
 * @export
 * @class PDFApi
 * @extends {BaseAPI}
 */
export class PDFApi extends BaseAPI {
    /**
     * 
     * @summary Get Classlist PDF
     * @param {string} schoolId 
     * @param {string} className 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PDFApi
     */
    public pdfControllerClassList(schoolId: string, className: string, options?: AxiosRequestConfig) {
        return PDFApiFp(this.configuration).pdfControllerClassList(schoolId, className, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary LEO-Manual/Practice PDF
     * @param {string} book 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PDFApi
     */
    public pdfControllerDownloadBook(book: string, options?: AxiosRequestConfig) {
        return PDFApiFp(this.configuration).pdfControllerDownloadBook(book, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Evaluate Test Result of User
     * @param {string} id 
     * @param {string} form 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PDFApi
     */
    public pdfControllerEvaluateStudent(id: string, form: string, options?: AxiosRequestConfig) {
        return PDFApiFp(this.configuration).pdfControllerEvaluateStudent(id, form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Evaluate Test Result of User
     * @param {string} form 
     * @param {string} grade 
     * @param {string} schoolId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PDFApi
     */
    public pdfControllerGetClassEvaluation(form: string, grade: string, schoolId: string, options?: AxiosRequestConfig) {
        return PDFApiFp(this.configuration).pdfControllerGetClassEvaluation(form, grade, schoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Code for Single Student PDF
     * @param {string} schoolId 
     * @param {string} className 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PDFApi
     */
    public pdfControllerSingleCode(schoolId: string, className: string, id: string, options?: AxiosRequestConfig) {
        return PDFApiFp(this.configuration).pdfControllerSingleCode(schoolId, className, id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestResultsApi - axios parameter creator
 * @export
 */
export const TestResultsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete all test results of all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerDeleteAllUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete test result of e2e-user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerDeleteE2E: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/results/e2e-user/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all test results of one user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerDeleteOneUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resultsControllerDeleteOneUser', 'userId', userId)
            const localVarPath = `/results/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all test results of all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all test results of one user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerFindOne: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resultsControllerFindOne', 'userId', userId)
            const localVarPath = `/results/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the specific results from one test of one user. Creates default entries if entry does not exist yet.
         * @param {string} userId 
         * @param {string} form 
         * @param {string} test 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerGetTestStatus: async (userId: string, form: string, test: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resultsControllerGetTestStatus', 'userId', userId)
            // verify required parameter 'form' is not null or undefined
            assertParamExists('resultsControllerGetTestStatus', 'form', form)
            // verify required parameter 'test' is not null or undefined
            assertParamExists('resultsControllerGetTestStatus', 'test', test)
            const localVarPath = `/results/{userId}/{form}/{test}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"form"}}`, encodeURIComponent(String(form)))
                .replace(`{${"test"}}`, encodeURIComponent(String(test)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save specific test result
         * @param {string} userId 
         * @param {TestResultDto} testResultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerSaveTestResult: async (userId: string, testResultDto: TestResultDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resultsControllerSaveTestResult', 'userId', userId)
            // verify required parameter 'testResultDto' is not null or undefined
            assertParamExists('resultsControllerSaveTestResult', 'testResultDto', testResultDto)
            const localVarPath = `/results/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testResultDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestResultsApi - functional programming interface
 * @export
 */
export const TestResultsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestResultsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete all test results of all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resultsControllerDeleteAllUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resultsControllerDeleteAllUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete test result of e2e-user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resultsControllerDeleteE2E(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resultsControllerDeleteE2E(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete all test results of one user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resultsControllerDeleteOneUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resultsControllerDeleteOneUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all test results of all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resultsControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resultsControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all test results of one user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resultsControllerFindOne(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resultsControllerFindOne(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the specific results from one test of one user. Creates default entries if entry does not exist yet.
         * @param {string} userId 
         * @param {string} form 
         * @param {string} test 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resultsControllerGetTestStatus(userId: string, form: string, test: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resultsControllerGetTestStatus(userId, form, test, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save specific test result
         * @param {string} userId 
         * @param {TestResultDto} testResultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resultsControllerSaveTestResult(userId: string, testResultDto: TestResultDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resultsControllerSaveTestResult(userId, testResultDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestResultsApi - factory interface
 * @export
 */
export const TestResultsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestResultsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete all test results of all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerDeleteAllUser(options?: any): AxiosPromise<void> {
            return localVarFp.resultsControllerDeleteAllUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete test result of e2e-user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerDeleteE2E(options?: any): AxiosPromise<void> {
            return localVarFp.resultsControllerDeleteE2E(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all test results of one user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerDeleteOneUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resultsControllerDeleteOneUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all test results of all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerFindAll(options?: any): AxiosPromise<ResultsDto> {
            return localVarFp.resultsControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all test results of one user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerFindOne(userId: string, options?: any): AxiosPromise<ResultsDto> {
            return localVarFp.resultsControllerFindOne(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the specific results from one test of one user. Creates default entries if entry does not exist yet.
         * @param {string} userId 
         * @param {string} form 
         * @param {string} test 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerGetTestStatus(userId: string, form: string, test: string, options?: any): AxiosPromise<TestResultDto> {
            return localVarFp.resultsControllerGetTestStatus(userId, form, test, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save specific test result
         * @param {string} userId 
         * @param {TestResultDto} testResultDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resultsControllerSaveTestResult(userId: string, testResultDto: TestResultDto, options?: any): AxiosPromise<TestResultDto> {
            return localVarFp.resultsControllerSaveTestResult(userId, testResultDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestResultsApi - object-oriented interface
 * @export
 * @class TestResultsApi
 * @extends {BaseAPI}
 */
export class TestResultsApi extends BaseAPI {
    /**
     * 
     * @summary Delete all test results of all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestResultsApi
     */
    public resultsControllerDeleteAllUser(options?: AxiosRequestConfig) {
        return TestResultsApiFp(this.configuration).resultsControllerDeleteAllUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete test result of e2e-user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestResultsApi
     */
    public resultsControllerDeleteE2E(options?: AxiosRequestConfig) {
        return TestResultsApiFp(this.configuration).resultsControllerDeleteE2E(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete all test results of one user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestResultsApi
     */
    public resultsControllerDeleteOneUser(userId: string, options?: AxiosRequestConfig) {
        return TestResultsApiFp(this.configuration).resultsControllerDeleteOneUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all test results of all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestResultsApi
     */
    public resultsControllerFindAll(options?: AxiosRequestConfig) {
        return TestResultsApiFp(this.configuration).resultsControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all test results of one user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestResultsApi
     */
    public resultsControllerFindOne(userId: string, options?: AxiosRequestConfig) {
        return TestResultsApiFp(this.configuration).resultsControllerFindOne(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the specific results from one test of one user. Creates default entries if entry does not exist yet.
     * @param {string} userId 
     * @param {string} form 
     * @param {string} test 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestResultsApi
     */
    public resultsControllerGetTestStatus(userId: string, form: string, test: string, options?: AxiosRequestConfig) {
        return TestResultsApiFp(this.configuration).resultsControllerGetTestStatus(userId, form, test, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save specific test result
     * @param {string} userId 
     * @param {TestResultDto} testResultDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestResultsApi
     */
    public resultsControllerSaveTestResult(userId: string, testResultDto: TestResultDto, options?: AxiosRequestConfig) {
        return TestResultsApiFp(this.configuration).resultsControllerSaveTestResult(userId, testResultDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new user for a school
         * @param {string} schoolId 
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateUser: async (schoolId: string, createUserDto: CreateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('userControllerCreateUser', 'schoolId', schoolId)
            // verify required parameter 'createUserDto' is not null or undefined
            assertParamExists('userControllerCreateUser', 'createUserDto', createUserDto)
            const localVarPath = `/users/{schoolId}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create many users for a school / csv import
         * @param {string} schoolId 
         * @param {string} className 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateUsers: async (schoolId: string, className: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('userControllerCreateUsers', 'schoolId', schoolId)
            // verify required parameter 'className' is not null or undefined
            assertParamExists('userControllerCreateUsers', 'className', className)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('userControllerCreateUsers', 'requestBody', requestBody)
            const localVarPath = `/users/{schoolId}/{className}/students`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)))
                .replace(`{${"className"}}`, encodeURIComponent(String(className)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user by id
         * @param {string} id 
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteOneUser: async (id: string, schoolId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerDeleteOneUser', 'id', id)
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('userControllerDeleteOneUser', 'schoolId', schoolId)
            const localVarPath = `/users/{schoolId}/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetOneUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerGetOneUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get patients/students of a therapist/parent
         * @param {string} schoolId 
         * @param {string} shared 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetPatients: async (schoolId: string, shared: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('userControllerGetPatients', 'schoolId', schoolId)
            // verify required parameter 'shared' is not null or undefined
            assertParamExists('userControllerGetPatients', 'shared', shared)
            const localVarPath = `/users/patients/{schoolId}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (shared !== undefined) {
                localVarQueryParameter['shared'] = shared;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all teachers or students, patients or children
         * @param {string} schoolId 
         * @param {string} [grade] Get student of particular grade. Optional
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetTeachersOrStudentsOrPatientsOrChildren: async (schoolId: string, grade?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schoolId' is not null or undefined
            assertParamExists('userControllerGetTeachersOrStudentsOrPatientsOrChildren', 'schoolId', schoolId)
            const localVarPath = `/users/filter/{schoolId}`
                .replace(`{${"schoolId"}}`, encodeURIComponent(String(schoolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserByEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('userControllerGetUserByEmail', 'email', email)
            const localVarPath = `/users/email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset E2E user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerResetE2EUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/e2e-user/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUser: async (id: string, updateUserDto: UpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerUpdateUser', 'id', id)
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('userControllerUpdateUser', 'updateUserDto', updateUserDto)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new user for a school
         * @param {string} schoolId 
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateUser(schoolId: string, createUserDto: CreateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateUser(schoolId, createUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create many users for a school / csv import
         * @param {string} schoolId 
         * @param {string} className 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateUsers(schoolId: string, className: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateUsers(schoolId, className, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user by id
         * @param {string} id 
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerDeleteOneUser(id: string, schoolId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerDeleteOneUser(id, schoolId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetOneUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetOneUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get patients/students of a therapist/parent
         * @param {string} schoolId 
         * @param {string} shared 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetPatients(schoolId: string, shared: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetPatients(schoolId, shared, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all teachers or students, patients or children
         * @param {string} schoolId 
         * @param {string} [grade] Get student of particular grade. Optional
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetTeachersOrStudentsOrPatientsOrChildren(schoolId: string, grade?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetTeachersOrStudentsOrPatientsOrChildren(schoolId, grade, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetUserByEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUserByEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset E2E user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerResetE2EUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerResetE2EUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a user
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateUser(id: string, updateUserDto: UpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUser(id, updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new user for a school
         * @param {string} schoolId 
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateUser(schoolId: string, createUserDto: CreateUserDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.userControllerCreateUser(schoolId, createUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create many users for a school / csv import
         * @param {string} schoolId 
         * @param {string} className 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateUsers(schoolId: string, className: string, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerCreateUsers(schoolId, className, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user by id
         * @param {string} id 
         * @param {string} schoolId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteOneUser(id: string, schoolId: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.userControllerDeleteOneUser(id, schoolId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetOneUser(id: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.userControllerGetOneUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get patients/students of a therapist/parent
         * @param {string} schoolId 
         * @param {string} shared 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetPatients(schoolId: string, shared: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.userControllerGetPatients(schoolId, shared, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all teachers or students, patients or children
         * @param {string} schoolId 
         * @param {string} [grade] Get student of particular grade. Optional
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetTeachersOrStudentsOrPatientsOrChildren(schoolId: string, grade?: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.userControllerGetTeachersOrStudentsOrPatientsOrChildren(schoolId, grade, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetUserByEmail(email: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.userControllerGetUserByEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset E2E user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerResetE2EUser(options?: any): AxiosPromise<UserDto> {
            return localVarFp.userControllerResetE2EUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateUser(id: string, updateUserDto: UpdateUserDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.userControllerUpdateUser(id, updateUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Create a new user for a school
     * @param {string} schoolId 
     * @param {CreateUserDto} createUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerCreateUser(schoolId: string, createUserDto: CreateUserDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerCreateUser(schoolId, createUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create many users for a school / csv import
     * @param {string} schoolId 
     * @param {string} className 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerCreateUsers(schoolId: string, className: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerCreateUsers(schoolId, className, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user by id
     * @param {string} id 
     * @param {string} schoolId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerDeleteOneUser(id: string, schoolId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerDeleteOneUser(id, schoolId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetOneUser(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerGetOneUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get patients/students of a therapist/parent
     * @param {string} schoolId 
     * @param {string} shared 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetPatients(schoolId: string, shared: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerGetPatients(schoolId, shared, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all teachers or students, patients or children
     * @param {string} schoolId 
     * @param {string} [grade] Get student of particular grade. Optional
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetTeachersOrStudentsOrPatientsOrChildren(schoolId: string, grade?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerGetTeachersOrStudentsOrPatientsOrChildren(schoolId, grade, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by email
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerGetUserByEmail(email: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerGetUserByEmail(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset E2E user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerResetE2EUser(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerResetE2EUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user
     * @param {string} id 
     * @param {UpdateUserDto} updateUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public userControllerUpdateUser(id: string, updateUserDto: UpdateUserDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).userControllerUpdateUser(id, updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}


